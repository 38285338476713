/** @jsx jsx */
import { jsx } from "@emotion/core"
import { css, keyframes } from "@emotion/core"
import { StaticQuery, graphql } from "gatsby"
import Img from "../../components/core/img"

/*
 * How this works: each property on this object represents a set of logos for a
 * shape. Each entry in the array holds the name of an image file (the images
 * must be saved in src/images/integrations/ with the same name). The alt is
 * used as the image alt tag, so put something useful in there.
 */
const imageData = {
  source: [
    { name: `contentful.png`, alt: `Contentful` },
    { name: `wordpress.png`, alt: `WordPress` },
    { name: `drupal.png`, alt: `Drupal` },
    { name: `markdown.png`, alt: `Markdown` },
    { name: `google-sheets.png`, alt: `Google Sheets` },
  ],
  style: [
    { name: `sass.png`, alt: `Sass` },
    { name: `less.png`, alt: `Less` },
    { name: `postcss.png`, alt: `PostCSS` },
  ],
  js: [
    { name: `javascript.png`, alt: `JavaScript` },
    { name: `react.png`, alt: `React` },
  ],
  measure: [
    { name: `google-analytics.png`, alt: `Google Analytics` },
    { name: `segment.png`, alt: `Segment` },
  ],
  deploy: [
    { name: `aws-s3.png`, alt: `Amazon AWS S3` },
    { name: `netlify.png`, alt: `Netlify` },
    { name: `akamai.png`, alt: `Akamai` },
  ],
}

// Dynamically build animations for every length of images present.
const animations = Object.values(imageData).reduce((animObj, imgArray) => {
  const count = imgArray.length
  const name = `swap${count}`

  if (animObj.hasOwnProperty(name)) {
    return animObj
  }

  const animation = keyframes`
    0% {
      top: -100%;
    }

    2% {
      top: 20%;
    }

    ${100 / count - 2}% {
      top: 20%;
    }

    ${100 / count}% {
      top: 100%
    }
  `

  return {
    ...animObj,
    [name]: animation,
  }
}, {})

const getImageData = images => ({ name, alt }) => {
  const currentImage = images.find(
    image => image.node.childImageSharp.fluid.originalName === name
  )

  if (!currentImage) {
    throw new Error(`No image ${name} found!`)
  }

  return {
    fluid: currentImage.node.childImageSharp.fluid,
    alt,
  }
}

const image = {
  animationTimingFunction: `linear`,
  animationIterationCount: `infinite`,
  left: `20%`,
  width: `60%`,
  position: `absolute`,
  top: `100%`,
}

// How long (in ms) to wait between image cycling
const ANIMATION_ADVANCE_DELAY = 3000
const showImages = (
  images,
  meta,
  { initialDelay = 0, animationDirection = `normal` } = {}
) => {
  const directionModifier = animationDirection === `normal` ? 1 : -1
  const filteredImages = meta.map(getImageData(images))
  const animationName = animations[`swap${filteredImages.length}`]

  return filteredImages.map((img, index) => {
    const delayModifier = index * directionModifier
    const delay = ANIMATION_ADVANCE_DELAY * delayModifier + initialDelay
    const animation = css`
      animation-delay: ${delay}ms;
      animation-direction: ${animationDirection};
      animation-duration: ${ANIMATION_ADVANCE_DELAY * filteredImages.length}ms;
      animation-name: ${animationName};
    `

    return (
      <div key={img.alt} css={[animation, image]}>
        <Img alt={img.alt} fluid={img.fluid} />
      </div>
    )
  })
}

const cell = {
  flex: `1 150px`,
  height: `0`,
  maxWidth: `20%`,
  paddingBottom: `20%`,
  position: `relative`,
}

const shape = {
  display: `block`,
}

const double = {
  height: `0`,
  position: `absolute`,
}

const shapeOne = {
  background: `#e9f6fb`,
  clipPath: `polygon(
    50% 0%,
    93.5% 25%,
    93.5% 75%,
    50% 100%,
    6.5% 75%,
    6.5% 25%
  )`,
}

const shapeTwo = {
  background: `#F9EFF4`,
  clipPath: `polygon(
    0% 10%,
    90% 0%,
    100% 90%,
    10% 100%
  )`,
  paddingBottom: `50%`,
  top: `5%`,
  transform: `rotate(-6deg)`,
  transformOrigin: `top right`,
  width: `50%`,
}

const shapeThree = {
  background: `#FCE55C`,
  clipPath: `polygon(
    0% 10%,
    90% 0%,
    100% 90%,
    10% 100%
  )`,
  left: `48%`,
  mixBlendMode: `multiply`,
  paddingBottom: `52%`,
  top: `42%`,
  transform: `rotate(-2deg)`,
  transformOrigin: `bottom right`,
  width: `52%`,
}

const shapeFour = {
  background: `#ECF7F1`,
  clipPath: `polygon(
    50% 0%,
    100% 36%,
    81% 95%,
    19% 95%,
    0% 36%
  )`,
}

const shapeFive = {
  background: `#FCE9E9`,
  clipPath: `circle(50%)`,
}

const styles = {
  integrationsShowcase: {
    display: `none`,
    justifyContent: `space-between`,
    margin: `80px auto`,
    maxWidth: `850px`,
    "@supports (clip-path: polygon(0 0))": {
      display: `flex`,
    },
  },
}

const IntegrationsShowcase = () => (
  <StaticQuery
    query={graphql`
      query IntegrationsImagesQuery {
        images: allFile(filter: { relativePath: { regex: "/integrations/" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 102, maxHeight: 102) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={({ images: { edges } }) => (
      <div css={styles.integrationsShowcase}>
        <div css={[cell, shape, shapeOne]}>
          {showImages(edges, imageData.source, { initialDelay: 100 })}
        </div>
        <div css={cell}>
          <div css={[shape, double, shapeTwo]}>
            {showImages(edges, imageData.style, {
              initialDelay: 200,
              animationDirection: `reverse`,
            })}
          </div>
          <div css={[shape, double, shapeThree]}>
            {showImages(edges, imageData.js, { initialDelay: 300 })}
          </div>
        </div>
        <div css={[cell, shape, shapeFour]}>
          {showImages(edges, imageData.measure, { initialDelay: 400 })}
        </div>
        <div css={[cell, shape, shapeFive]}>
          {showImages(edges, imageData.deploy, {
            initialDelay: 500,
            animationDirection: `reverse`,
          })}
        </div>
      </div>
    )}
  />
)

export default IntegrationsShowcase
