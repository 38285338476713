import React from "react"
import propTypes from "prop-types"
import {
  mediaQueries,
  colors,
  fontSizes,
  lineHeights,
  space,
} from "../../utils/presets"
import { smallCapsStyles } from "../../utils/styles"
import Img from "../../components/core/img"

import Circle from "!url-loader!svgo-loader!../../assets/integrations-circle.svg"
import DoubleSquares from "!url-loader!svgo-loader!../../assets/integrations-two-squares.svg"
import Hexagon from "!url-loader!svgo-loader!../../assets/integrations-hexagon.svg"
import Polygon from "!url-loader!svgo-loader!../../assets/integrations-polygon.svg"

const styles = {
  integrationsList: {
    paddingTop: space[13],
  },
  integrationsListSection: {
    alignItems: `stretch`,
    display: `flex`,
    justifyContent: `space-between`,
    marginBottom: space[11],
    flexDirection: `column`,
    [mediaQueries.tablet]: {
      flexDirection: `row`,
    },
  },
  integrationsListSectionInfo: {
    flex: 2,
    marginRight: space[9],
  },
  integrationsListSectionTitleContainer: {
    alignItems: `center`,
    display: `flex`,
    justifyContent: `space-between`,
    marginBottom: space[9],
    position: `relative`,
  },
  integrationsListSectionTitleLine: {
    backgroundColor: colors.ui.light,
    flex: 1,
    height: 1,
  },
  integrationsListSectionTitleImage: {
    width: space[7],
    margin: 0,
    marginRight: space[3],
  },
  integrationsListSectionTitle: {
    ...smallCapsStyles,
    color: colors.gatsby,
    marginBottom: 0,
    paddingRight: space[5],
    lineHeight: lineHeights.loose,
  },
  integrationsListSectionDescription: {
    fontSize: fontSizes[2],
    paddingRight: `4em`,
    display: `none`,
    [mediaQueries.tablet]: {
      display: `block`,
    },
  },
  integrationsListSectionLogos: {
    alignItems: `center`,
    display: `flex`,
    gap: `1em`,
    flex: 3,
    flexWrap: `wrap`,
    justifyContent: `center`,
    [mediaQueries.tablet]: {
      justifyContent: `flex-start`,
    },
  },

  imgStyles: {
    margin: 0,
  },
  logo: {
    margin: `${space[2]} ${space[6]} ${space[2]} 0`,
    width: space[9],
  },
}

const renderTitleBgShape = index => {
  switch (index) {
    case 0:
      return (
        <img
          css={styles.integrationsListSectionTitleImage}
          src={Hexagon}
          alt="Hexagon"
        />
      )
    case 1:
      return (
        <img
          css={styles.integrationsListSectionTitleImage}
          src={DoubleSquares}
          alt="Two Squares"
        />
      )
    case 2:
      return (
        <img
          css={styles.integrationsListSectionTitleImage}
          src={Polygon}
          alt="Polygon"
        />
      )
    case 3:
      return (
        <img
          css={styles.integrationsListSectionTitleImage}
          src={Circle}
          alt="Circle"
        />
      )
    default:
      return null
  }
}

const IntegrationsList = ({ page }) => (
  <div css={styles.integrationsList}>
    {page.sections[0].logoBoxes.map((logoBox, index) => (
      <div key={logoBox.id} css={styles.integrationsListSection}>
        <div css={styles.integrationsListSectionInfo}>
          <div css={styles.integrationsListSectionTitleContainer}>
            <div css={styles.integrationsListSectionTitleShape}>
              {renderTitleBgShape(index)}
            </div>
            <h3 css={styles.integrationsListSectionTitle}>{logoBox.header}</h3>
            <div css={styles.integrationsListSectionTitleLine} />
          </div>
          <p css={styles.integrationsListSectionDescription}>
            {logoBox.description.description}
          </p>
        </div>
        <div css={styles.integrationsListSectionLogos}>
          {logoBox.logos.map(logo => (
            <div css={styles.logo} key={logo.id}>
              {logo?.fluid ? (
                <Img
                  alt={logo.title}
                  title={logo.title}
                  fluid={logo.fluid}
                  imgStyle={styles.imgStyles}
                />
              ) : (
                <img
                  loading="lazy"
                  alt={logo.title}
                  title={logo.title}
                  src={logo.file.url}
                  style={styles.imgStyles}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

IntegrationsList.propTypes = {
  page: propTypes.object.isRequired,
}

export default IntegrationsList
